<template>
  <div class="allcontens">
    <div class="content">
      <div class="warp">
        <img
          width="160"
          height="80"
          src="https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg"
        />
        <img
          width="160"
          height="80"
          src="https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg"
        />
        <img
          width="160"
          height="80"
          src="https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg"
        />
        <img
          width="160"
          height="80"
          src="https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg"
        />
      </div>
      <van-field
        disabled
        v-model="formData.name"
        name="name"
        label="客户姓名"
        input-align="right"
        placeholder="客户姓名"
      />
      <van-field
        disabled
        v-model="formData.phone"
        name="phone"
        label="联系方式"
        input-align="right"
        placeholder="联系方式"
      />
      <van-field
        disabled
        v-model="formData.idcard"
        name="name"
        label="身份证号"
        input-align="right"
        placeholder="身份证号"
      />
      <van-field
        disabled
        v-model="formData.bankNo"
        name="bankNo"
        label="银行卡号码"
        input-align="right"
        placeholder="银行卡号码"
      />
      <van-field
        disabled
        v-model="formData.amount"
        name="amount"
        label="订单金额"
        input-align="right"
        placeholder="订单金额"
      />
      <van-field
        disabled
        v-model="formData.downPayment"
        name="downPayment"
        label="首付款"
        input-align="right"
        placeholder="首付款"
      />
      <van-field
        disabled
        v-model="formData.installmentNum"
        name="installmentNum"
        label="分期数"
        input-align="right"
        placeholder="分期数"
      />
      <van-field
        disabled
        v-model="formData.installmentAmount"
        name="installmentAmount"
        label="分期金额"
        input-align="right"
        placeholder="分期金额"
      />
      <van-field
        disabled
        v-model="formData.singlePeriodAmount"
        name="singlePeriodAmount"
        label="每期应还"
        input-align="right"
        placeholder="每期应还"
      />
      <div style="margin: 16px" class="row justify-center">
        <van-button
          round
          type="info"
          size="small"
          class="margin-top-50 next-btn"
          @click="confirm"
          native-type="submit"
        >
          确认</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      insuranceNo: "",
      formData: {},
    };
  },
  async created() {
    if (/MicroMessenger/.test(window.navigator.userAgent)) {
      // 微信客户端
      this.payWay = "wxH5";
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
      // 支付宝客户端
      this.payWay = "alipayH5";
    } else {
      // 其他浏览器
      this.payWay = "wxH5";
    }
    this.insuranceNo = this.$route.query.insuranceNo;
  },
  methods: {
    async confirm() {
      this.$router.push({
        path: "/sign/account",
        query: { insuranceNo: this.insuranceNo },
      });
    },
  },
};
</script>
<style scoped>
.warp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.warp img {
  object-fit: contain;
  border: 1px solid #eeeeee;
  border-radius: 4rpx;
}
</style>
